import React, { FC } from "react";

import Image from "next/image";
import Link from "next/link";

import { handleFixImageUrl } from "helpers";

import { toClassName } from "helpers/format";

import { APIResponseData } from "types/models";

import { styles } from ".";

type TProps = {
  type?: "primary" | "secondary" | "tertiary" | "quaternary";
  //
  article?: APIResponseData<"api::article.article">;
  //
  lang: string;
};

const ArticleItemCustom: FC<TProps> = (props) => {
  const { type, article, lang } = props;

  return (
    <Link href={`/${lang}/${article?.attributes?.slug}`}>
      <div
        className={toClassName(
          styles.container,
          type === "tertiary" && "sm:!flex-row",
          type === "quaternary" && "sm:!flex-row",
        )}
      >
        <div className={toClassName(type === "tertiary" && "sm:w-[15rem]")}>
          <div
            className={toClassName(
              styles.image,
              type === "tertiary" && "sm:!h-[120px]",
              type === "quaternary" && "sm:!h-[60px]",
            )}
          >
            <Image
              src={handleFixImageUrl(
                article?.attributes?.image?.data?.attributes?.url ?? "",
              )}
              fill
              alt={
                article?.attributes?.image?.data?.attributes?.alternativeText ??
                "no-alt"
              }
              className="object-cover"
            />
          </div>
        </div>

        <div className={styles.body}>
          <h2
            className={toClassName(
              styles.title,
              type === "primary" && "text-4xl py-2.5",
              type === "secondary" && "text-2xl py-2",
              type === "tertiary" && "text-xl",
              type === "quaternary" && "text-sm",
            )}
          >
            {article?.attributes?.title}
          </h2>
          {type !== "quaternary" && (
            <p className={styles.description}>
              {article?.attributes?.description}
            </p>
          )}
        </div>
      </div>
    </Link>
  );
};

export default ArticleItemCustom;
